import React from "react";
import { Col } from "react-grid-system";
import useGetMetricLogs from "../../hooks/useGetMetricLogs";

import Graph from "../graph";
import LoadingSpinner from "../components/LoadingSpinner";

function WarningAlert({ error }) {
    return (
        <div className="alert alert-warning">
            <strong>Warning!</strong> {error}
        </div>
    );
}

function EmptyComponent() {
    return (
        <div className="alert">
            <strong>No data found!</strong>
        </div>
    );
}

function MetricCard({ metricName, eventId, environment, startTime, endTime, CardTitle }) {
    const { metricLogs, error, isLoaded } = useGetMetricLogs(
        metricName,
        eventId,
        environment,
        startTime,
        endTime
    );

    let cardBodyContent;
    if (isLoaded) {
        if (metricLogs.length === 0 || metricLogs["log_data"].length === 0) {
            cardBodyContent = <EmptyComponent />;
        } else {
            cardBodyContent = (
                <Graph
                    log_data={metricLogs["log_data"]}
                    key_list={metricLogs["key_list"]}
                    legend_enabled={true}
                />
            );
        }
    } else if (error) {
        cardBodyContent = <WarningAlert error={error} />;
    } else {
        cardBodyContent = <LoadingSpinner />;
    }

    return (
        <Col className="mb-3 px-2" sm={4}>
            <div className="card rounded-3 shadow-sm">
                <div className="card-header">
                    <div className="row">
                        <div className="col-8 card_title">
                            <h5 className="my-0 fw-normal">{CardTitle}</h5>
                        </div>
                    </div>
                </div>
                <div className="card-body text-center">{cardBodyContent}</div>
            </div>
        </Col>
    );
}

export default MetricCard;
