import "./RequestUrls.css";
import React, { useState } from "react";
import useGetHealthLogs from "../../hooks/useGetHealthLogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faXmark, faPlus, faGear } from "@fortawesome/free-solid-svg-icons";

import DetailsTable from "../detailsView/DetailsTable.js";
import { Col, Row } from "react-grid-system";
import LoadingSpinner from "../components/LoadingSpinner";

function ErrorAlert({ error }) {
    return (
        <div className="alert alert-danger">
            <strong>Warning!</strong> {error}
        </div>
    );
}

function TimeOutAlert({ refetchQueryResults }) {
    return (
        <div className="alert alert-warning">
            <strong>Time Out!</strong> Please refresh after some time
            <button
                id="downloadButton"
                className="btn btn-sm btn-primary mx-2"
                onClick={refetchQueryResults}
                title="Refresh"
            >
                <FontAwesomeIcon icon={faArrowsRotate} />
            </button>
        </div>
    );
}

function KeyBadges({ extraQueryArg, removeExtraQueryArg }) {
    return (
        <Row className="mx-2">
            {Object.keys(extraQueryArg).map((key) =>
                extraQueryArg[key] != "" ? (
                    <div className="mx-2" key={key}>
                        <button
                            className="btn btn-sm btn-primary search_key_badge_button"
                            onClick={() => removeExtraQueryArg(key)}
                        >
                            <label className="pr-2">
                                {key}: {extraQueryArg[key]}
                            </label>
                        </button>
                        <button
                            className="btn btn-sm btn-primary query_key_remove_button"
                            onClick={() => removeExtraQueryArg(key)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                ) : (
                    ""
                )
            )}
        </Row>
    );
}

let search_key_list = ["statusCode", "remoteAddr", "upstreamAddr", "url"];
let allColumnKeyList = [
    "time",
    "statusCode",
    "url",
    "remoteAddr",
    "upstreamAddr",
    "userAgent",
    "requestTime",
    "upstreamTime",
    "dateTimeEpoch",
    "requestMethod",
    "requestProtocol",
    "bytes",
    "referrer",
    "serverName",
    "forwaredFor",
    "cacheStatus",
    "upstreacCacheControl",
    "upstreamExpires",
    "cdn",
];
let emptyDict = {};
search_key_list.forEach((key) => {
    emptyDict[key] = "";
});

function RequestUrls({ eventId, environment, startTime, endTime, theme }) {
    const [queryId, setQueryId] = useState(null);
    const [extraQueryArg, setExtraQueryArg] = useState(emptyDict);
    const [extraQueryArgFlag, setExtraQueryArgFlag] = useState(0);
    const [selectedColumnKeyList, setSelectedColumnKeyList] = useState([
        "time",
        "statusCode",
        "url",
        "remoteAddr",
        "upstreamAddr",
        "userAgent",
        "requestTime",
        "upstreamTime",
    ]);

    const { queryLogs, error, isLoaded, isTimeOut, refetchQueryResults } = useGetHealthLogs(
        "filter_url_by_multiple_fields",
        eventId,
        environment,
        startTime,
        endTime,
        setQueryId,
        extraQueryArg
    );

    const [selectedKey, setSelectedKey] = useState(search_key_list[0]);
    const [selectedValue, setSelectedValue] = useState("");

    function updateExtraQueryArg() {
        if (selectedKey && selectedValue) {
            extraQueryArg[selectedKey] = selectedValue;
            setSelectedKey(search_key_list[0]);
            setSelectedValue("");
            setExtraQueryArgFlag(Math.random());
        }
    }

    function removeExtraQueryArg(key) {
        if (key) {
            extraQueryArg[key] = "";
            setSelectedKey(search_key_list[0]);
            setSelectedValue("");
            setExtraQueryArgFlag(Math.random());
        }
    }

    function getStatusCodesNot200() {
        const statusCodesCount = {};
        if (isLoaded && queryLogs.log_data.length > 0) {
            for (const item of queryLogs.log_data) {
                if (item.statusCode !== "200") {
                    if (!statusCodesCount[item.statusCode]) {
                        statusCodesCount[item.statusCode] = 1;
                    } else {
                        statusCodesCount[item.statusCode]++;
                    }
                    item.warning = 1;
                }
            }
        }
        return statusCodesCount;
    }

    const handleSelectColumn = (option) => {
        if (selectedColumnKeyList.includes(option)) {
            setSelectedColumnKeyList(selectedColumnKeyList.filter((item) => item !== option));
        } else {
            setSelectedColumnKeyList([...selectedColumnKeyList, option]);
        }
    };

    let cardBodyContent;
    if (isLoaded) {
        cardBodyContent = (
            <div>
                <DetailsTable
                    logData={queryLogs.log_data}
                    keyList={allColumnKeyList.filter((item) =>
                        selectedColumnKeyList.includes(item)
                    )}
                    theme={theme}
                />
            </div>
        );
    } else if (error) {
        cardBodyContent = <ErrorAlert error={error} />;
    } else if (isTimeOut) {
        cardBodyContent = <TimeOutAlert refetchQueryResults={refetchQueryResults} />;
    } else {
        cardBodyContent = <LoadingSpinner />;
    }

    return (
        <div className="pb-3 px-2">
            <Row>
                <Col>
                    <Row className="mx-3 mt-2 mb-3">
                        <div className="mx-2">
                            <select
                                className="form-select"
                                id="keyList"
                                value={selectedKey}
                                onChange={(e) => setSelectedKey(e.target.value)}
                            >
                                {search_key_list.map((item, index) => (
                                    <option value={item} key={index}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mx-2">
                            <input
                                type="text"
                                className="form-control"
                                id="value"
                                name="value"
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                                placeholder="Value"
                            />
                        </div>
                        <button className="btn btn-primary" onClick={updateExtraQueryArg}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Row>
                    <Row className="mx-3 mt-2 mb-3">
                        <div className="mx-2">
                            <button className="btn btn-primary" onClick={refetchQueryResults}>
                                Filter urls
                            </button>
                        </div>
                    </Row>
                    <Row className="mx-3 mt-2">{getColumnDropDown()}</Row>
                </Col>
                <Col>
                    <KeyBadges
                        extraQueryArg={extraQueryArg}
                        removeExtraQueryArg={removeExtraQueryArg}
                    />
                </Col>
                <Col>
                    <Row className="mx-3 mt-2 mb-3 status_code_row">
                        {Object.entries(getStatusCodesNot200()).map(
                            ([statusCode, count], index) => (
                                <label
                                    key={index}
                                    className={`text-light status_code_badge bg-danger`}
                                >
                                    status {statusCode}: {count}
                                </label>
                            )
                        )}
                    </Row>
                </Col>
            </Row>

            {cardBodyContent}
        </div>
    );

    function getColumnDropDown() {
        return (
            <div className="drop_down mx-2">
                <button className="drop_down_btn btn btn-sm btn-primary" title="Select columns">
                    Select columns
                </button>
                <div
                    className={`drop_down_content rounded p-1 ${
                        theme == "dark" ? "bg-dark" : "bg-light"
                    }`}
                >
                    {(() => {
                        let optionsPerRow = 4;
                        const elements = [];
                        for (let i = 0; i < allColumnKeyList.length; i += optionsPerRow) {
                            const options = allColumnKeyList.slice(i, i + optionsPerRow);
                            const row = (
                                <div className="drop_down_content_col" key={i}>
                                    {options.map((option, index) => (
                                        <button
                                            key={index}
                                            className={`drop_down_item btn col-lg-${Math.floor(
                                                12 / optionsPerRow
                                            )}${
                                                selectedColumnKeyList.includes(option)
                                                    ? " btn-primary"
                                                    : ""
                                            }`}
                                            onClick={() => handleSelectColumn(option)}
                                        >
                                            {option}
                                        </button>
                                    ))}
                                </div>
                            );
                            elements.push(row);
                        }
                        return elements;
                    })()}
                </div>
            </div>
        );
    }
}

export default RequestUrls;
