import "./Filter.css";
import React, { useState } from "react";
import { Row, Col } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilter,
    faMoon,
    faUpRightFromSquare,
    faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

import Config from "../../config.json";
import InfoModal from "./InfoModal";

const HEALTH_CHECK_CLIENT_URL = Config.HEALTH_CHECK_CLIENT_URL;
const ENVIRONMENT_LIST = Config.ENVIRONMENT_LIST;
const STREAMCHECK_AUTH_URL = Config.STREAMCHECK_AUTH_URL;

function Filter({
    eventId,
    environment,
    duration,
    startTime,
    endTime,
    eventDetails,
    theme,
    toggleTheme,
    activeTabUrl,
}) {
    const [durationState, setDurationState] = useState(duration);
    const [showInfoModal, setShowInfoModal] = useState(false);

    function handleSelectTime(buttonValue) {
        setDurationState(buttonValue);
    }

    function filterEvent() {
        const baseURL = HEALTH_CHECK_CLIENT_URL;
        const eventId = document.getElementById("eventIdInput").value;
        const environment = document.getElementById("environmentIdInput").value;
        const startDateInput = document.getElementById("startDateInput");
        const endDateInput = document.getElementById("endDateInput");

        let queryParams = "";
        if (durationState !== "Range") {
            queryParams = `duration=PT${durationState}`;
        } else if (startDateInput && endDateInput) {
            const startTime = startDateInput.value;
            const endTime = endDateInput.value;
            queryParams = `startTime=${startTime}&endTime=${endTime}`;
        }

        const url = `${baseURL}?environment=${environment}&event_id=${eventId}&${queryParams}#${activeTabUrl}`;
        window.location.href = url;
    }

    function filterEventTimeRange() {
        const baseURL = HEALTH_CHECK_CLIENT_URL;
        const eventId = document.getElementById("eventIdInput").value;
        const environment = document.getElementById("environmentIdInput").value;

        let queryParams = `startTime=${eventDetails["streamStartDateTime"]}&endTime=${eventDetails["streamEndDateTime"]}#${activeTabUrl}`;
        const url = `${baseURL}?environment=${environment}&event_id=${eventId}&${queryParams}`;
        window.location.href = url;
    }

    function openConsoleUrl() {
        let consoleUrl =
            STREAMCHECK_AUTH_URL.split("login")[0] +
            "ui/beta/live-stream/schedule/event/" +
            eventId;
        window.open(consoleUrl, "_blank", "noreferrer");
    }

    return (
        <div
            className={`filter_nav card shadow-sm my-2 rounded ${
                theme == "dark" ? "" : "bg-light"
            }`}
        >
            <div className="card-body">
                <Row className="mb-1">
                    {getEventInputs()}

                    <div className="d-flex">
                        <div className="vr"></div>
                    </div>

                    <Col className="time_filter_col">{eventDetails && getEventDetailsView()}</Col>
                </Row>
                {eventDetails && getProgressBar()}
            </div>
            <div>
                <InfoModal
                    showInfoModal={showInfoModal}
                    setShowInfoModal={setShowInfoModal}
                    theme={theme}
                />
            </div>
        </div>
    );

    function getProgressBar() {
        let progress = calculateProgress(
            eventDetails["streamStartDateTime"],
            eventDetails["streamEndDateTime"]
        );
        return (
            <Row className="progress mt-4 progress_bar" style={{ height: "3px" }}>
                <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
                <div
                    className="progress-bar bg-secondary"
                    role="progressbar"
                    style={{ width: `${100 - progress}%` }}
                    aria-valuenow={100 - progress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </Row>
        );
    }

    function getEventDetailsView() {
        return (
            <Row>
                <Col sm={8}>
                    <Row>
                        <label className="event_label">Description:</label>
                        <Col className="event_details_value text-start">
                            <span>{eventDetails["description"]}</span>
                        </Col>
                    </Row>
                    <Row>
                        <label className="event_label">Channel ID:</label>
                        <span>{eventDetails["channelId"]}</span>
                    </Row>
                    <Row>
                        <label className="event_label">Event Status:</label>
                        <span>{eventDetails["eventStatus"]}</span>
                    </Row>
                </Col>
                <Col>
                    {getThemeButton()}
                    <Row>
                        <label className="event_label">Start Time:</label>
                        <span>{eventDetails["streamStartDateTime"]}</span>
                    </Row>
                    <Row>
                        <label className="event_label">End Time:</label>
                        <span>{eventDetails["streamEndDateTime"]}</span>
                    </Row>
                    <Row className="mt-2">
                        <button className="btn btn-sm btn-primary" onClick={filterEventTimeRange}>
                            Filter Event time
                        </button>
                    </Row>
                    {/* <Row className="mt-2">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={openConsoleUrl}
                            title="Open in console"
                        >
                            <span>Open in console </span>
                            <FontAwesomeIcon icon={faUpRightFromSquare} />
                        </button>
                    </Row> */}
                </Col>
            </Row>
        );
    }

    function getThemeButton() {
        return (
            <Row className="theme_buttons">
                <button
                    className={`btn ${theme === "light" ? "mt-1" : ""}`}
                    onClick={toggleTheme}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={theme === "light" ? "Dark Theme" : "Light Theme"}
                >
                    {theme == "light" ? <FontAwesomeIcon icon={faMoon} /> : getSunIconSvg()}
                </button>
                <button
                    className="btn mt-1"
                    onClick={() => setShowInfoModal(true)}
                    data-toggle="tooltip"
                    data-placement="left"
                    title={"Info"}
                >
                    <FontAwesomeIcon icon={faCircleInfo} />
                </button>
            </Row>
        );
    }

    function getEventInputs() {
        return (
            <Col className="border-right" sm={6}>
                {getEventAndEnvInputs()}

                <Row>
                    <Col>
                        <Row className="time_buttons mb-2">
                            <div className="flex-content">
                                <div
                                    className="btn-group center"
                                    role="group"
                                    aria-label="Time Range"
                                >
                                    {["1H", "2H", "Range"].map((time) => (
                                        <button
                                            key={time}
                                            type="button"
                                            className={`duration_btn btn btn-outline-primary btn-sm me-1 
                                                    ${
                                                        time === durationState
                                                            ? "btn-primary text-light"
                                                            : ""
                                                    }
                                                    `}
                                            defaultValue={time}
                                            onClick={() => handleSelectTime(time)}
                                        >
                                            {time}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </Row>

                        <Row className="filter_button mt-2">
                            <div className="">
                                <button className="btn btn-sm btn-primary" onClick={filterEvent}>
                                    <FontAwesomeIcon icon={faFilter} className="filter_icon" />
                                    Filter
                                </button>
                            </div>
                        </Row>
                    </Col>

                    <Col>
                        {durationState === "Range" && (
                            <>
                                <Row className="mb-2">
                                    <label htmlFor="startDateInput" className="form-label center">
                                        Start Date
                                    </label>
                                    <Col>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            id="startDateInput"
                                            name="startTime"
                                            defaultValue={startTime}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <label htmlFor="endDateInput" className="form-label center">
                                        End Date
                                    </label>
                                    <Col>
                                        <input
                                            type="datetime-local"
                                            className="form-control mt-2"
                                            id="endDateInput"
                                            name="endTime"
                                            defaultValue={endTime}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            </Col>
        );
    }

    function getEventAndEnvInputs() {
        return (
            <Row className="mb-3">
                <label htmlFor="eventIdInput" className="form-label center">
                    Event ID
                </label>
                <Col>
                    <input
                        type="text"
                        className="form-control"
                        id="eventIdInput"
                        name="eventId"
                        defaultValue={eventId}
                    />
                </Col>
                <label htmlFor="eventIdInput" className="form-label center">
                    Environment
                </label>
                <Col>
                    <select
                        className="form-select"
                        id="environmentIdInput"
                        name="environment"
                        defaultValue={environment}
                    >
                        {ENVIRONMENT_LIST.map((env) => (
                            <option key={env} value={env}>
                                {env}
                            </option>
                        ))}
                    </select>
                </Col>
            </Row>
        );
    }

    function getSunIconSvg() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 256 256"
                width="20"
            >
                <g fill="#fff" fillRule="evenodd">
                    <g transform="scale(5.12)">
                        <path d="M24.906 3.969c-.043.008-.086.02-.125.031-.465.105-.793.523-.781 1v6c-.004.359.184.695.496.879.312.18.695.18 1.008 0 .312-.184.5-.52.496-.879v-6c.012-.289-.105-.566-.312-.762-.211-.199-.496-.297-.781-.27zM10.656 9.844c-.375.066-.676.34-.781.703-.105.367.004.758.281 1.016l4.25 4.25c.242.297.629.434 1.004.348.371-.086.664-.379.75-.75.086-.375-.051-.762-.348-1.004l-4.25-4.25c-.207-.223-.508-.336-.813-.313-.031 0-.062 0-.094 0zM39.031 9.844c-.227.031-.437.145-.594.313l-4.25 4.25c-.297.242-.434.629-.348 1.004.086.371.379.664.75.75.375.086.762-.051 1.004-.348l4.25-4.25c.313-.297.402-.762.219-1.152-.187-.395-.602-.621-1.031-.566zM25 15c-5.516 0-10 4.484-10 10 0 5.516 4.484 10 10 10 5.516 0 10-4.484 10-10 0-5.516-4.484-10-10-10zM4.719 24c-.551.078-.937.59-.859 1.141.078.551.59.937 1.141.859h6c.359.004.695-.184.879-.496.18-.312.18-.695 0-1.008-.184-.312-.52-.5-.879-.496h-6c-.031 0-.062 0-.094 0-.031 0-.062 0-.094 0-.031 0-.062 0-.094 0zM38.719 24c-.551.078-.937.59-.859 1.141.078.551.59.937 1.141.859h6c.359.004.695-.184.879-.496.18-.312.18-.695 0-1.008-.184-.312-.52-.5-.879-.496h-6c-.031 0-.062 0-.094 0-.031 0-.062 0-.094 0-.031 0-.062 0-.094 0zM15 33.875c-.227.031-.438.145-.594.313l-4.25 4.25c-.297.242-.434.629-.348 1.004.086.371.379.664.75.75.375.086.762-.051 1.004-.348l4.25-4.25c.297-.285.387-.727.227-1.105-.156-.379-.535-.621-.945-.613-.031 0-.062 0-.094 0zM34.687 33.875c-.375.066-.676.34-.781.703-.105.367.004.758.281 1.016l4.25 4.25c.242.297.629.434 1.004.348.371-.086.664-.379.75-.75.086-.375-.051-.762-.348-1.004l-4.25-4.25c-.187-.199-.445-.309-.719-.313-.031 0-.062 0-.094 0-.031 0-.062 0-.094 0-.031 0-.062 0-.094 0zM24.906 37.969c-.043.008-.086.02-.125.031-.465.105-.793.523-.781 1v6c-.004.359.184.695.496.879.312.18.695.18 1.008 0 .312-.183.5-.52.496-.879v-6c.012-.289-.105-.566-.312-.761-.211-.199-.496-.297-.781-.269z" />
                    </g>
                </g>
            </svg>
        );
    }

    function calculateProgress(startTimeString, endTimeString) {
        let startTime = new Date(startTimeString);
        let endTime = new Date(endTimeString);
        let now = new Date(getUTCFormattedTime());
        now = now <= startTime ? startTime : now;
        now = now >= endTime ? endTime : now;
        let progress = 100 - (Math.abs(endTime - now) * 100) / (endTime - startTime);
        return progress;
    }

    function getUTCFormattedTime() {
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const hours = String(now.getUTCHours()).padStart(2, "0");
        const minutes = String(now.getUTCMinutes()).padStart(2, "0");
        const formattedTime = `${year}-${month}-${day}T${hours}:${minutes}`;
        return formattedTime;
    }
}

export default Filter;
