import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

function Graph({ log_data, key_list, legend_enabled }) {
    let keys = [...key_list];
    let dataset = [...log_data];
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const index = keys.indexOf("time");
    if (index !== -1) {
        keys.splice(index, 1);
    }
    const times = dataset.map((obj) => obj.time.split(" ")[1].split(":").slice(0, 2).join(":"));
    const color_list = [
        "#36a2eb",
        "#e74c3c",
        "#2ecc71",
        "#ff9f40",
        "#ff6384",
        "#ffcd56",
        "#4bc0c0",
        "#9966ff",
        "#3498db",
        "#ffd700",
    ];
    const borderColor = {};
    keys.forEach((key, index) => {
        borderColor[key] = color_list[index % color_list.length];
    });

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        const datasets = keys.map((label) => ({
            label,
            data: dataset.map((obj) => obj[label]),
            borderColor: borderColor[label],
            borderWidth: 1.5,
            fill: false,
            pointStyle: false,
        }));

        const chart = new Chart(ctx, {
            type: "line",
            data: { labels: times, datasets },
            options: {
                plugins: {
                    legend: {
                        display: legend_enabled,
                        labels: {
                            usePointStyle: true,
                            pointStyle: "rectRounded",
                            generateLabels: function (chart) {
                                const labels =
                                    Chart.defaults.plugins.legend.labels.generateLabels(chart);

                                labels.forEach((label) => {
                                    label.fillStyle = borderColor[label.text];
                                });

                                return labels;
                            },
                        },
                    },
                },
                options: {
                    scales: {
                        xAxes: [
                            {
                                type: "time",
                                time: { unit: "minute" },
                                scaleLabel: { display: true, labelString: "Time" },
                            },
                        ],
                        yAxes: [{ scaleLabel: { display: true, labelString: "Variables" } }],
                    },
                },
            },
        });

        chartInstanceRef.current = chart;

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [dataset, keys]);

    return <canvas ref={chartRef}></canvas>;
}

export default Graph;
