import { useState, useEffect } from "react";
import ApiConfig from "../services/ApiConfig";
import Config from "../config.json";
import axios from "axios";

const StartQuery = (queryName, eventId, environment, startTime, endTime, extraQueryArg) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = await ApiConfig();
            config.method = "get";
            config.url +=
                "/start_health_check_query?" +
                "query_name=" +
                queryName +
                "&environment=" +
                environment +
                "&event_id=" +
                eventId +
                "&start_time=" +
                startTime +
                "&end_time=" +
                endTime;

            if (extraQueryArg) {
                config.url += "&extra_query_arg=" + JSON.stringify(extraQueryArg);
            }

            const response = await axios(config);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

const GetQueryResults = (queryId, queryName, environment) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = await ApiConfig();
            config.method = "get";
            config.url += `/get_query_results?query_id=${queryId}&query_name=${queryName}&environment=${environment}`;
            const response = await axios(config);
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    });
};

const useGetHealthLogs = (
    queryName,
    eventId,
    environment,
    startTime,
    endTime,
    setQueryId,
    extraQueryArg = null,
    isLoadNow = true
) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isTimeOut, setIsTimeOut] = useState(false);
    const [queryLogs, setQueryLogs] = useState([]);

    const intervalLimit = 10;
    const intervalTimeOut = Config.CW_INTERVAL_TIMEOUT_IN_SEC * 1000;

    const refetchQueryResults = async () => {
        setIsLoaded(false);
        setIsTimeOut(false);
        startQuery();
    };

    const queryResultsInterval = async (query_id) => {
        let isGetResultSuccess = false;
        let intervalCount = 0;

        while (!isGetResultSuccess && intervalLimit > intervalCount) {
            try {
                const result = await GetQueryResults(query_id, queryName, environment);
                if (result.status === "Complete") {
                    setQueryLogs(result);
                    setIsLoaded(true);
                    isGetResultSuccess = true;
                } else {
                    await new Promise((resolve) => {
                        intervalCount++;
                        setTimeout(resolve, intervalTimeOut);
                    });
                }
            } catch (error) {
                setError(error);
            }
        }
        if (!isLoaded)
        {
            setIsTimeOut(true)
        }
    };

    const startQuery = async () => {
        try {
            const startRes = await StartQuery(
                queryName,
                eventId,
                environment,
                startTime,
                endTime,
                extraQueryArg
            );
            if (startRes !== "Network Error") {
                const query_id = startRes.query_id;
                setQueryId(query_id);

                await queryResultsInterval(query_id);
            } else {
                setError(startRes);
            }
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        setIsLoaded(false);
        if (isLoadNow) {
            startQuery();
        }
    }, [extraQueryArg]);

    return { queryLogs, error, isLoaded, isTimeOut, refetchQueryResults };
};

export default useGetHealthLogs;
