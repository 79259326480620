import { useState, useEffect } from "react";
import ApiConfig from "../services/ApiConfig";
import axios from "axios";

const getInstanceMetrics = (metricName, eventId, environment, startTime, endTime) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = await ApiConfig();
            config.method = "get";
            config.url += "/get_instance_metrics?" +
            "metric_name=" +
            metricName +
            "&environment=" +
            environment +
            "&event_id=" +
            eventId +
            "&start_time=" +
            startTime +
            "&end_time=" +
            endTime;
            const response = await axios(config);
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};

const useGetMetricLogs = (metricName, eventId, environment, startTime, endTime) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [metricLogs, setMetricLogs] = useState([]);

    useEffect(() => {
        const startMetric = async () => {
            try {
                const results = await getInstanceMetrics(metricName, eventId, environment, startTime, endTime);
                setMetricLogs(results)
                setIsLoaded(true)
            } catch (error) {
                setError(error);
            }
        };

        startMetric();
    }, [metricName, eventId, environment, startTime, endTime]);

    return { metricLogs, error, isLoaded };
};

export default useGetMetricLogs;
