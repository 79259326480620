import "./Home.css";
import "./Theme.css";
import React, { useState, useEffect, memo } from "react";
import { Container } from "react-grid-system";
import { toast } from "react-toastify";
import moment from "moment";

import Filter from "../filter";
import Login from "../login";
import Health from "../health";
import Archive from "../archive";
import RequestUrls from "../requestUrls";
import Resources from "../resources";
import StatusCodeView from "../statusCodeView";
import useGetEventDetailsApi from "../../hooks/useGetEventDetails";

const MemoizedHealth = memo(Health);
const MemoizedArchive = memo(Archive);
const MemoizedRequestUrls = memo(RequestUrls);
const MemoizedResource = memo(Resources);
const MemoizedStatusCodeView = memo(StatusCodeView);

function ErrorAlert({ error }) {
    return (
        <div className="alert alert-danger">
            <strong>Warning!</strong> {error}
        </div>
    );
}

function Home() {
    var { eventId, environment, startTime, endTime, duration } = getParams();
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

    const { eventDetails, error, isLoaded, refetchEventDetails } = useGetEventDetailsApi(
        eventId,
        environment
    );

    if (startTime && endTime && !duration) {
        const startDateTime = new Date(startTime);
        const endDateTime = new Date(endTime);
        if (startDateTime >= endDateTime) {
            toast.error("End time needs to be greater than start time");
        }
        duration = "Range";
    } else {
        duration = duration ? duration.substring(2) : "1H";
        const durationTime = moment.duration(`PT${duration}`);
        const currentTime = moment.utc();
        endTime = currentTime.format("YYYY-MM-DDTHH:mm");
        const newTime = currentTime.subtract(durationTime);
        startTime = newTime.format("YYYY-MM-DDTHH:mm");
    }

    const toggleTheme = () => {
        const newTheme = theme == "light" ? "dark" : "light";
        setTheme(newTheme);
    };

    const tabs = [
        {
            title: "Health",
            tab_url: "health",
            content: (
                <MemoizedHealth
                    eventId={eventId}
                    environment={environment}
                    startTime={startTime}
                    endTime={endTime}
                    theme={theme}
                />
            ),
        },
        {
            title: "Requests",
            tab_url: "requests",
            content: (
                <MemoizedRequestUrls
                    eventId={eventId}
                    environment={environment}
                    startTime={startTime}
                    endTime={endTime}
                    theme={theme}
                />
            ),
        },
        {
            title: "Archive",
            tab_url: "archive",
            content: <MemoizedArchive eventId={eventId} environment={environment} theme={theme} />,
        },
        {
            title: "Resources",
            tab_url: "resources",
            content: <MemoizedResource eventId={eventId} environment={environment} theme={theme} />,
        },
        {
            title: "Status Code Stats",
            tab_url: "statusCode",
            content: (
                <MemoizedStatusCodeView
                    eventId={eventId}
                    environment={environment}
                    startTime={startTime}
                    endTime={endTime}
                    theme={theme}
                />
            ),
        },
    ];
    const [activeTabUrl, setactiveTabUrl] = useState(window.location.hash.slice(1));
    const initialActiveTab = tabs.findIndex((tab) => tab.tab_url === activeTabUrl);
    const [activeTab, setActiveTab] = useState(initialActiveTab !== -1 ? initialActiveTab : 0);

    const [tabsLoaded, setTabsLoaded] = useState(Array(tabs.length).fill(false));
    const theme_text = theme == "light" ? "text-dark" : "text-light";

    const markTabLoaded = (tabIndex) => {
        setTabsLoaded((prevTabsLoaded) => {
            const newTabsLoaded = [...prevTabsLoaded];
            newTabsLoaded[tabIndex] = true;
            return newTabsLoaded;
        });
    };

    useEffect(() => {
        if (!tabsLoaded[activeTab]) {
            setactiveTabUrl(tabs[activeTab].tab_url);
            markTabLoaded(activeTab);
        }
    }, [activeTab, tabsLoaded]);

    useEffect(() => {
        document.body.classList.remove("light-theme", "dark-theme");
        document.body.classList.add(theme + "-theme");
        localStorage.setItem("theme", theme);
    }, [theme]);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    if (localStorage.getItem("credential") === null) {
        return (
            <Container>
                <Login />
            </Container>
        );
    }

    return (
        <Container className={`home_class`}>
            <Filter
                eventId={eventId}
                environment={environment}
                duration={duration}
                startTime={startTime}
                endTime={endTime}
                eventDetails={eventDetails}
                theme={theme}
                toggleTheme={toggleTheme}
                activeTabUrl={activeTabUrl}
            />
            {!error ? getTabManager() : <ErrorAlert error={error} />}
        </Container>
    );

    function getParams() {
        const searchParams = new URLSearchParams(window.location.search);
        let searchParamsDict = Object.fromEntries(searchParams.entries());

        const eventId = searchParamsDict["event_id"];
        const environment = searchParamsDict["environment"];
        let startTime = searchParamsDict["startTime"];
        let endTime = searchParamsDict["endTime"];
        let duration = searchParamsDict["duration"];
        return { eventId, environment, startTime, endTime, duration };
    }

    function getTabManager() {
        return (
            <div className={`tab_manager_theme shadow mb-2 rounded mt-3`}>
                <ul className={`nav nav-tabs shadow-sm mb-2 rounded`}>
                    {tabs.map((tab, index) => (
                        <li
                            className={`nav-item ${activeTab === index ? "active" : ""}`}
                            key={index}
                        >
                            <div className="nav_item_div">
                                <a
                                    className={`nav-link bg-light ${theme_text} ${
                                        activeTab === index ? "active" : "not_active"
                                    }`}
                                    onClick={() => handleTabClick(index)}
                                    href={`#${tab.tab_url}`}
                                >
                                    {tab.title}
                                </a>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="tab-content pt-2 tab_content">
                    {tabs.map((tab, index) => (
                        <div
                            className={`tab-pane fade ${activeTab === index ? "show active" : ""}`}
                            key={index}
                        >
                            {tabsLoaded[index] ? tab.content : null}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Home;
