import React, { useState } from "react";
import { Col } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import archiveInstanceLogsApi from "../../api/archiveInstanceLogsApi";

function ArchiveInstance({ item, eventId, environment, index }) {
    const [isArchiveUpdating, setIsArchiveUpdating] = useState(false);
    const [isArchiveButton, setIsArchiveButton] = useState(false);
    const [isFileListCollapsed, setIsFileListCollapsed] = useState(true);

    const formatSize = (size) => {
        if (size < 1024) {
            return size.toFixed(2) + " KB";
        } else if (size < 1048576) {
            return (size / 1024).toFixed(2) + " MB";
        } else {
            return (size / 1048576).toFixed(2) + " GB";
        }
    };

    const handleArchiveLogs = async (instance_id) => {
        setIsArchiveUpdating(true);
        setIsArchiveButton(true);
        let result = await archiveInstanceLogsApi(eventId, environment, instance_id);
        setIsArchiveUpdating(false);
    };

    const toggleFileList = () => {
        setIsFileListCollapsed(!isFileListCollapsed);
    };

    return (
        <div key={index} className="card mt-2">
            <div className="card-header flex-content">
                <div className="mt-2 mx-2">
                    <h5 className="card-title ">Instance ID: {item.instance_id}</h5>
                </div>
                <div className="mx-2">
                    <button
                        className="btn btn-primary"
                        onClick={() => handleArchiveLogs(item.instance_id)}
                        disabled={isArchiveUpdating || isArchiveButton ? true : ""}
                    >
                        <FontAwesomeIcon icon={faBoxArchive} />
                    </button>
                </div>
                {item.file_list.length > 0 && (
                    <>
                        <Col>
                            <div className="mt-2 mx-2">
                                <h5 className="card-title ">
                                    Total size: {formatSize(item.total_size)}
                                </h5>
                            </div>
                        </Col>
                        <Col>
                            <div className="mt-2 mx-2">
                                <h5 className="card-title ">Files: {item.file_list.length}</h5>
                            </div>
                        </Col>
                        <div className="mx-2">
                            <button className="btn btn-primary" onClick={toggleFileList}>
                                {isFileListCollapsed ? (
                                    <FontAwesomeIcon icon={faChevronDown} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronUp} />
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
            {!isFileListCollapsed && item.file_list.length > 0 && (
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>File Name</th>
                                <th>Size</th>
                                <th>Time</th>
                                <th>File Path</th>
                            </tr>
                        </thead>
                        <tbody>
                            {item.file_list.map((file, fileIndex) => (
                                <tr key={fileIndex}>
                                    <td>{file.file_name}</td>
                                    <td>{formatSize(file.size)}</td>
                                    <td>{file.time}</td>
                                    <td>{file.file_path}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default ArchiveInstance;
