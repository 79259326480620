import React, { useState } from "react";
import { Col } from "react-grid-system";
import useGetHealthLogs from "../../hooks/useGetHealthLogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faDownload, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

import Graph from "../graph";
import GetQueryCsvApi from "../../api/getQueryCsvApi";
import LoadingSpinner from "../components/LoadingSpinner";

function formatQueryName(str) {
    const firstLetter = str.charAt(0).toUpperCase();
    const restOfString = str.slice(1);
    const formattedString = restOfString.replace(/_/g, " ");
    return firstLetter + formattedString;
}

function ErrorAlert({ error }) {
    return (
        <div className="alert alert-danger">
            <strong>Warning!</strong> {error}
        </div>
    );
}

function TableComponent({ alertList }) {
    let cardAlertList = alertList;
    let isMoreAlerts = false;
    if (alertList.length > 8) {
        cardAlertList = alertList.slice(0, 8);
        isMoreAlerts = true;
    }
    return (
        <>
            <table className="table table-borderless text-center">
                <thead>
                    <tr>
                        {Object.keys(alertList[0]).map((key) => (
                            <th scope="col" key={key}>
                                {key}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="small text-center">
                    {cardAlertList.map((row, index) => (
                        <tr key={index}>
                            {Object.values(row).map((col, colIndex) => (
                                <td key={colIndex}>{col}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {isMoreAlerts ? (
                <label className="text-danger">More alerts on details window</label>
            ) : (
                ""
            )}
        </>
    );
}

function OkComponent() {
    return <p>Everything looks ok!</p>;
}

function HealthCard({
    queryName,
    eventId,
    environment,
    startTime,
    endTime,
    updateDetailsViewData,
}) {
    const [queryId, setQueryId] = useState(null);
    const { queryLogs, error, isLoaded, isTimeOut, refetchQueryResults } = useGetHealthLogs(
        queryName,
        eventId,
        environment,
        startTime,
        endTime,
        setQueryId
    );

    const handleDetailsViewClick = () => {
        updateDetailsViewData(queryLogs);
    };

    const downloadCsvFile = () => {
        GetQueryCsvApi(queryId, queryName, eventId, environment, startTime, endTime);
    };

    const TimeOutAlert = () => {
        return (
            <div className="alert alert-warning">
                <strong>Time Out!</strong> Please refresh after some time
                <button
                    id="downloadButton"
                    className="btn btn-sm btn-primary mx-2"
                    onClick={refetchQueryResults}
                    title="Refresh"
                >
                    <FontAwesomeIcon icon={faArrowsRotate} />
                </button>
            </div>
        );
    };

    let cardBodyContent;
    if (isLoaded) {
        if (queryLogs["alert_list"].length > 0) {
            cardBodyContent = <TableComponent alertList={queryLogs["alert_list"]} />;
        } else if (queryLogs["health_card"].includes("graph") && queryLogs["log_data"].length > 0) {
            cardBodyContent = (
                <Graph
                    log_data={queryLogs["log_data"]}
                    key_list={queryLogs["key_list"]}
                    legend_enabled={false}
                />
            );
        } else {
            cardBodyContent = <OkComponent />;
        }
    } else if (error) {
        cardBodyContent = <ErrorAlert error={error} />;
    } else if (isTimeOut) {
        cardBodyContent = <TimeOutAlert />;
    } else {
        cardBodyContent = <LoadingSpinner />;
    }

    return (
        <Col className="mb-3 px-2" sm={4}>
            <div className="card rounded-3 shadow-sm">
                <div className="card-header">
                    <div className="row">
                        <div className="col-8 card_title">
                            <h5 className="my-0 fw-normal">{formatQueryName(queryName)}</h5>
                        </div>
                        <div className="col action_buttons_col">
                            <button
                                id="downloadButton"
                                className="btn btn-sm btn-primary"
                                onClick={downloadCsvFile}
                                title="Download CSV"
                                disabled={!isLoaded}
                            >
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                            <button
                                id="detailsButton"
                                className="btn btn-sm btn-primary"
                                onClick={handleDetailsViewClick}
                                title="Info"
                                disabled={!isLoaded}
                            >
                                <FontAwesomeIcon icon={faInfo} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body text-center">{cardBodyContent}</div>
            </div>
        </Col>
    );
}

export default HealthCard;
