import "./DetailsView.css";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Row, Col } from "react-grid-system";
import Graph from "../graph";
import DetailsTable from "./DetailsTable.js";

function DetailsView({ showDetailsView, setShowDetailsView, detailsData, theme }) {
    const handleClose = () => setShowDetailsView(false);

    const renderInstructions = (items) =>
        items.map((item) => (
            <React.Fragment key={item.title}>
                <a>{item.title}</a>
                <ul>
                    {item.details && item.details.map((detail) => <li key={detail}>{detail}</li>)}
                </ul>
            </React.Fragment>
        ));

    return (
        <div>
            {detailsData.status === "Complete" && (
                <Modal
                    show={showDetailsView}
                    onHide={() => setShowDetailsView(false)}
                    dialogClassName="details_modal_width"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={detailsData.health_card.includes("graph") ? 5 : 12}>
                                <div className="card">
                                    <div className="card-body row">
                                        <Col>
                                            <h5>What looks good:</h5>
                                            {renderInstructions(detailsData.instructions.good)}
                                        </Col>
                                        <Col>
                                            <h5>What looks bad:</h5>
                                            {renderInstructions(detailsData.instructions.bad)}
                                        </Col>
                                        <Col>
                                            <h5>What to do:</h5>
                                            {renderInstructions(detailsData.instructions.action)}
                                        </Col>
                                    </div>
                                </div>
                            </Col>
                            {detailsData.health_card.includes("graph") && (
                                <Col>
                                    <div className="card">
                                        <div className="card-body">
                                            <Graph
                                                log_data={detailsData.log_data}
                                                key_list={detailsData.key_list}
                                                legend_enabled={true}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col sm={detailsData.alert_list.length > 0 ? 8 : 12}>
                                <DetailsTable
                                    logData={detailsData.log_data}
                                    keyList={detailsData.key_list}
                                    theme={theme}
                                />
                            </Col>
                            {detailsData.alert_list.length > 0 && (
                                <Col>
                                    <div className="card mt-3">
                                        <div className="card-body">
                                            <table className="table table-borderless text-center">
                                                <thead>
                                                    <tr>
                                                        {Object.keys(detailsData.alert_list[0]).map(
                                                            (key) => (
                                                                <th scope="col" key={key}>
                                                                    {key}
                                                                </th>
                                                            )
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody className="small text-center">
                                                    {detailsData.alert_list.map((row, index) => (
                                                        <tr key={index}>
                                                            {Object.values(row).map(
                                                                (col, colIndex) => (
                                                                    <td key={colIndex}>{col}</td>
                                                                )
                                                            )}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
}

export default DetailsView;
