import ApiConfig from "../services/ApiConfig";
import axios from "axios";
const fileDownload = require('js-file-download');

const GetQueryCsvApi = async (queryId, queryName, eventId, environment, startTime, endTime) => {
    var config = await ApiConfig();
    config.method = "get";
    config.url +=
        "/get_query_csv?query_id=" +
        queryId +
        "&query_name=" +
        queryName +
        "&environment=" +
        environment +
        "&event_id=" +
        eventId +
        "&startTime=" +
        startTime +
        "&endTime=" +
        endTime;
    return await axios(config)
        .then(function (response) {
            fileDownload(response.data, queryName
                + "-"
                + eventId
                + "-"
                + environment
                + "-"
                + startTime
                + "-to-"
                + endTime
                + ".csv");
            return true;
        })
        .catch(function (error) {
            return error.message;
        });
};

export default GetQueryCsvApi;