import { useState, useEffect } from "react";
import ApiConfig from "../services/ApiConfig";
import axios from "axios";

const GetResources = (eventId, environment, resource_api) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = await ApiConfig();
            config.method = "get";
            config.url +=
                "/" + resource_api + "?" + "environment=" + environment + "&event_id=" + eventId;
            const response = await axios(config);
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    });
};

const useGetResources = (eventId, environment, resource_api) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [resources, setResources] = useState(null);

    const refetchResources = async () => {
        setIsRefetching(true);
        try {
            const response = await GetResources(eventId, environment, resource_api);
            setIsLoaded(true);
            setResources(response);
            setIsRefetching(false);
        } catch (error) {
            setError(error);
        }
    };
    useEffect(() => {
        const GetEvent = async () => {
            try {
                const response = await GetResources(eventId, environment, resource_api);
                setIsLoaded(true);
                setResources(response);
            } catch (error) {
                setError(error);
            }
        };

        GetEvent();
    }, []);

    return { resources, error, isLoaded, refetchResources, isRefetching };
};

export default useGetResources;
