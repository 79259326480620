import "./Health.css";
import React, { useState } from "react";
import { Row } from "react-grid-system";

import HealthCard from "./HealthCard.js";
import DetailsView from "../detailsView";
import MetricCard from "./MetricCard.js";

const queryNames = [
    "event_response_times",
    "slow_clients",
    "http_status_codes",
    "bytes_served",
    "request_rate",
    "frequent_segment_requests",
    
    // hevc specific queries
    "hevc_event_response_times",
    "hevc_http_status_codes",
    "hevc_bytes_served",
    "hevc_request_rate",
    "hevc_frequent_segment_requests",
];
const metricNames = [
    { cardTitle: "CPU Utilization (%)", metricName: "CPUUtilization" },
    { cardTitle: "Network Out (KB)", metricName: "NetworkOut" },
    { cardTitle: "Disk Write (KB)", metricName: "DiskWriteBytes" },
];

function Health({ eventId, environment, startTime, endTime, theme }) {
    const [showDetailsView, setShowDetailsView] = useState(false);
    const [detailsData, setDetailsData] = useState([]);

    let homeBodyContent;
    if (eventId && environment) {
        homeBodyContent = (
            <Row className="mx-0">
                {queryNames.map((queryName, index) => (
                    <HealthCard
                        key={index}
                        queryName={queryName}
                        eventId={eventId}
                        environment={environment}
                        startTime={startTime}
                        endTime={endTime}
                        updateDetailsViewData={updateDetailsViewData}
                    />
                ))}

                {metricNames.map((metric, index) => (
                    <MetricCard
                        key={index}
                        CardTitle={metric.cardTitle}
                        metricName={metric.metricName}
                        eventId={eventId}
                        environment={environment}
                        startTime={startTime}
                        endTime={endTime}
                    />
                ))}
            </Row>
        );
    }

    function updateDetailsViewData(data) {
        setDetailsData(data);
        setShowDetailsView(true);
    }

    return (
        <div>
            {homeBodyContent}

            <DetailsView
                showDetailsView={showDetailsView}
                setShowDetailsView={setShowDetailsView}
                detailsData={detailsData}
                theme={theme}
            />
        </div>
    );
}

export default Health;
