import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Row } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function InfoModal({ showInfoModal, setShowInfoModal, theme }) {
    const handleClose = () => setShowInfoModal(false);

    function openConfluenceUrl() {
        window.open(
            "https://m2amedia.atlassian.net/wiki/spaces/OP/pages/3976855574/Streamcheck+-+Event+Healthcheck+Ops+1st+step+in+Triage",
            "_blank",
            "noreferrer"
        );
    }

    return (
        <div>
            <Modal
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title mx-3">Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mx-3 px-4">
                        <Row>
                            <label>
                                This app shows the overall health of an event. It runs queries on
                                CloudWatch Insights in <code>origin-cache-router-access</code> log
                                group and displays the query results.
                            </label>
                        </Row>
                        <Row className="mt-4">
                            <label>
                                For more info visit the{" "}
                                <a
                                    className="btn btn-sm btn-outline-primary"
                                    onClick={openConfluenceUrl}
                                    title="Open in console"
                                >
                                    <span>Confluence page </span>
                                    <FontAwesomeIcon icon={faUpRightFromSquare} />
                                </a>
                            </label>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default InfoModal;
