import Config from "../config"
import axios from 'axios';
const HEALTH_CHECK_API_URL = Config.HEALTH_CHECK_API_URL;

const LoginApi = async (credential) => {
    var config = {
        method: 'get',
        url: HEALTH_CHECK_API_URL + "/login",
        headers: {
            'Authorization': 'Basic ' + credential
        }
    };

    return await axios(config)
        .then(function (response) {
            return true
        })
        .catch(function (error) {
            return false
        });
}

export default LoginApi;