import React, { useState } from "react";

function DetailsTable({ logData, keyList, theme, itemsPerPage = 500 }) {
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(logData.length / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = logData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="card w-100 mt-3 shadow-sm">
            <div className="card-body table-wrapper">
                {logData.length > itemsPerPage && (
                    <nav>
                        <ul className="pagination pagination-sm details_table_pagination">
                            {Array.from({ length: totalPages }, (_, i) => (
                                <li
                                    key={i}
                                    className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                                >
                                    <button className="page-link" onClick={() => paginate(i + 1)}>
                                        {i + 1}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                )}
                <table className={`table ${theme === "dark" ? "table-striped table-dark" : ""}`}>
                    <thead>
                        <tr>
                            {keyList.map((item, index) => (
                                <th scope="col" key={index}>
                                    {item}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="small">
                        {currentItems.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {keyList.map((colKey, colIndex) => (
                                    <td key={colIndex}>{row[colKey]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DetailsTable;
