import React, { useState } from "react";
import { Row } from "react-grid-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";

import useGetResources from "../../hooks/useGetResources";
import LoadingSpinner from "../components/LoadingSpinner";

function Instances({ eventId, environment, theme }) {
    const { resources, error, isLoaded, refetchResources, isRefetching } = useGetResources(
        eventId,
        environment,
        "get_event_instances"
    );

    if (!isLoaded) {
        return <LoadingSpinner />;
    }

    return (
        <div className="mt-2">
            <div className="card">
                <Row className="card-header">
                    <div className="mx-4">
                        <h4>Instances</h4>
                    </div>
                    <div>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={refetchResources}
                            title="Refresh"
                            disabled={isRefetching}
                        >
                            <FontAwesomeIcon icon={faRotate} />
                        </button>
                    </div>
                    <div>
                        {isRefetching && (
                            <div
                                className="spinner-border spinner-border-sm text-primary m-2"
                                role="status"
                            >
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                    </div>
                </Row>
                {resources.length > 0 ? getInstanceTable() : <p className="my-2">No instance found!</p>}
            </div>
        </div>
    );

    function getInstanceTable() {
        return (
            <div className="card-body p-0">
                <table className={`table ${theme == "dark" ? "table-striped table-dark" : ""}`}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>State</th>
                            <th>Instance Type</th>
                            <th>Instance ID</th>
                            <th>Launch Time</th>
                            <th>Private IP Address</th>
                            <th>Public IP Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resources.map((resource, index) => (
                            <tr key={index}>
                                <td>{resource.instanceName}</td>
                                <td className={getInstanceColor(resource.state)}>
                                    {resource.state}
                                </td>
                                <td>{resource.instanceType}</td>
                                <td>{resource.instanceId}</td>
                                <td>{resource.launchTime}</td>
                                <td>{resource.privateIpAddress}</td>
                                <td>{resource.publicIpAddress}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Instances;

function getInstanceColor(state) {
    switch (state) {
        case "running":
            return "text-success";
        case "starting":
            return "text-warning";
        case "terminated":
            return "text-light";
        default:
            return "";
    }
}
