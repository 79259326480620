import ApiConfig from "../services/ApiConfig";
import axios from "axios";

const archiveInstanceLogsApi = async (eventId, environment, instance_id) => {
    var config = await ApiConfig();
    config.method = "get";
    config.url +=
        "/archive_event_instance_logs?" +
        "environment=" +
        environment +
        "&event_id=" +
        eventId +
        (instance_id ? "&instance_id=" + instance_id : "");
    return await axios(config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error.message;
        });
};

export default archiveInstanceLogsApi;