import Config from "../config"
const HEALTH_CHECK_API_URL = Config.HEALTH_CHECK_API_URL;

const ApiConfig = async () => {
    var credential = localStorage.getItem("credential");
    return {
        url: HEALTH_CHECK_API_URL,
        headers: {
            "Authorization": "Basic " + credential
        }
    };
}

export default ApiConfig;