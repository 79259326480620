import "./Resource.css";
import React from "react";

import Medialive from "./Medialive.js";
import Instances from "./Instances.js";

function Resource({ eventId, environment, theme }) {
    return (
        <div className="mx-2 pb-2">
            <div>
                <Instances eventId={eventId} environment={environment} theme={theme} />
            </div>
            <div>
                <Medialive eventId={eventId} environment={environment} theme={theme} />
            </div>
        </div>
    );
}

export default Resource;
