import './Login.css';
import React, { useState } from "react";
import { Buffer } from 'buffer';
import { Container, Row } from "react-grid-system";
import 'react-toastify/dist/ReactToastify.css';
import LoginApi from '../../services/LoginApi';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        const credential = Buffer.from(`${username}:${password}`).toString('base64');
        let response = await LoginApi(credential)
        if (response) {
            localStorage.setItem('credential', credential);
            window.location.reload();
        }
    };

    return (
        <Container >
            <div className='center mx-2'>
                <Row>
                    <h2>Login</h2>
                </Row>
                <Row>
                    <label>Username:</label>
                    <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Row>
                <Row className='mt-2'>
                    <label>Password:</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Row>
                <Row className='mt-2'>
                    <button className="btn btn-primary" onClick={handleLogin}>Login</button>
                </Row>
            </div>
        </Container>
    );
}

export default Login;
