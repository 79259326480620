import { useState, useEffect } from "react";
import ApiConfig from "../services/ApiConfig";
import axios from "axios";

const GetArchiveList = (eventId, environment) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = await ApiConfig();
            config.method = "get";
            config.url +=
                "/get_archive_list?" +
                "environment=" +
                environment +
                "&event_id=" +
                eventId;
            const response = await axios(config);
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    });
};

const useGetArchiveListApi = (eventId, environment) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [archiveLogs, setArchiveLogs] = useState([]);

    const refetchArchiveLogs = async () => {
        setIsLoaded(false);
        try {
            const response = await GetArchiveList(eventId, environment);
            setIsLoaded(true)
            setArchiveLogs(response)
        } catch (error) {
            setError(error);
        }
    };
    useEffect(() => {
        const GetArchive = async () => {
            try {
                const response = await GetArchiveList(eventId, environment);
                setIsLoaded(true)
                setArchiveLogs(response)
            } catch (error) {
                setError(error);
            }
        };

        GetArchive();

    }, []);

    return { archiveLogs, error, isLoaded, refetchArchiveLogs };
};

export default useGetArchiveListApi;
