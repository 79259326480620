import "./StatusCodeView.css";
import React, { useState } from "react";
import useGetHealthLogs from "../../hooks/useGetHealthLogs.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";

import DetailsTable from "../detailsView/DetailsTable.js";
import { Col, Row } from "react-grid-system";
import LoadingSpinner from "../components/LoadingSpinner.js";

function ErrorAlert({ error }) {
    return (
        <div className="alert alert-danger">
            <strong>Warning!</strong> {error}
        </div>
    );
}

function TimeOutAlert({ refetchQueryResults }) {
    return (
        <div className="alert alert-warning">
            <strong>Time Out!</strong> Please refresh after some time
            <button
                id="downloadButton"
                className="btn btn-sm btn-primary mx-2"
                onClick={refetchQueryResults}
                title="Refresh"
            >
                <FontAwesomeIcon icon={faArrowsRotate} />
            </button>
        </div>
    );
}

function KeyBadges({ extraQueryArg, removeExtraQueryArg }) {
    return (
        <Row className="mx-2">
            {Object.keys(extraQueryArg).map((key) =>
                extraQueryArg[key] != "" ? (
                    <div className="mx-2" key={key}>
                        <button
                            className="btn btn-sm btn-primary search_key_badge_button"
                            onClick={() => removeExtraQueryArg(key)}
                        >
                            <label className="pr-2">
                                {key}: {extraQueryArg[key]}
                            </label>
                        </button>
                        <button
                            className="btn btn-sm btn-primary query_key_remove_button"
                            onClick={() => removeExtraQueryArg(key)}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                ) : (
                    ""
                )
            )}
        </Row>
    );
}

let search_key_list = [
    "statusCode",
    "remoteAddr",
    "userAgent",
    "upstreamAddr",
    "forwaredFor",
    "cdn",
];
let emptyDict = {};
search_key_list.forEach((key) => {
    emptyDict[key] = "";
});

function StatusCodeView({ eventId, environment, startTime, endTime, theme }) {
    const [queryId, setQueryId] = useState(null);
    const [extraQueryArg, setExtraQueryArg] = useState(emptyDict);
    const [isRequestStrted, setIsRequestStrted] = useState(false);
    const [extraQueryArgFlag, setExtraQueryArgFlag] = useState(0);
    const [selectedKey, setSelectedKey] = useState(search_key_list[0]);
    const [selectedValue, setSelectedValue] = useState("");

    function updateExtraQueryArg() {
        if (selectedKey && selectedValue) {
            extraQueryArg[selectedKey] = selectedValue.trim();
            setSelectedKey(search_key_list[0]);
            setSelectedValue("");
            setExtraQueryArgFlag(Math.random());
        }
    }

    function removeExtraQueryArg(key) {
        if (key) {
            extraQueryArg[key] = "";
            setSelectedKey(search_key_list[0]);
            setSelectedValue("");
            setExtraQueryArgFlag(Math.random());
        }
    }

    const { queryLogs, error, isLoaded, isTimeOut, refetchQueryResults } = useGetHealthLogs(
        "filter_url_by_status_code",
        eventId,
        environment,
        startTime,
        endTime,
        setQueryId,
        extraQueryArg,
        false
    );

    function getRequestsUrls() {
        refetchQueryResults();
        setIsRequestStrted(true);
    }

    let cardBodyContent;
    if (isRequestStrted && queryLogs.length !== 0) {
        cardBodyContent = (
            <Row className="mt-2">
                {getLogFieldCount("remoteAddr")}
                {getLogFieldCount("forwaredFor")}
                {getLogFieldCount("upstreamAddr")}
                {getLogFieldCount("userAgent")}
                {getLogFieldCount("cdn")}
            </Row>
        );
    } else if (error) {
        cardBodyContent = <ErrorAlert error={error} />;
    } else if (isTimeOut) {
        cardBodyContent = <TimeOutAlert refetchQueryResults={refetchQueryResults} />;
    } else if (!isRequestStrted) {
        cardBodyContent = "";
    } else {
        cardBodyContent = "";
    }

    return (
        <div className="pb-3 px-2">
            <Row>
                <Col>
                    <Row className="mx-3 mt-2 mb-3">
                        <div className="mx-2">
                            <select
                                className="form-select"
                                id="keyList"
                                value={selectedKey}
                                onChange={(e) => setSelectedKey(e.target.value)}
                            >
                                {search_key_list.map((item, index) => (
                                    <option value={item} key={index}>
                                        {item}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="mx-2">
                            <input
                                type="text"
                                className="form-control"
                                id="value"
                                name="value"
                                value={selectedValue}
                                onChange={(e) => setSelectedValue(e.target.value)}
                                placeholder="Value"
                            />
                        </div>
                        <button className="btn btn-primary" onClick={updateExtraQueryArg}>
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </Row>
                    <Row className="mx-3 mt-2 mb-3">
                        <div className="mx-2">
                            <button className="btn btn-primary" onClick={getRequestsUrls}>
                                Filter urls
                            </button>
                        </div>
                    </Row>
                </Col>
                <Col>
                    <KeyBadges
                        extraQueryArg={extraQueryArg}
                        removeExtraQueryArg={removeExtraQueryArg}
                    />
                </Col>
                <Col className="mx-2 flex_reverse">
                    {!isLoaded && isRequestStrted ? <LoadingSpinner /> : recordsCountLabel()}
                </Col>
            </Row>

            {cardBodyContent}
        </div>
    );

    function getLogFieldCount(key) {
        const uniqueValueCount = {};

        for (let item of queryLogs.log_data) {
            if (uniqueValueCount[item[key]]) {
                uniqueValueCount[item[key]]++;
            } else {
                uniqueValueCount[item[key]] = 1;
            }
        }

        let keyCountList = Object.keys(uniqueValueCount).map((item) => ({
            [key]: item,
            count: uniqueValueCount[item],
        }));

        keyCountList.sort((a, b) => b.count - a.count);

        return (
            <Col lg={4} className="mb-3">
                <div className="card">
                    <h5 className="card-title mt-3">
                        {key.charAt(0).toUpperCase() +
                            key
                                .slice(1)
                                .replace(/([A-Z])/g, " $1")
                                .trim()}
                    </h5>
                    <DetailsTable
                        logData={keyCountList}
                        keyList={[key, "count"]}
                        theme={theme}
                        itemsPerPage={20}
                    />
                </div>
            </Col>
        );
    }

    function recordsCountLabel() {
        if (queryLogs.length !== 0) {
            return (
                <div className="">
                    <label className="mx-2">
                        Records matched: <strong>{queryLogs.statistics.recordsMatched}</strong>{" "}
                    </label>
                    <label>
                        Showing results: <strong>{queryLogs.log_data.length}</strong>{" "}
                    </label>
                </div>
            );
        }
    }
}

export default StatusCodeView;
