import "./Archive.css";
import React from "react";

import ArchiveInstance from "./ArchiveInstance.js";
import useGetArchiveListApi from "../../hooks/useGetArchiveListApi";
import LoadingSpinner from "../components/LoadingSpinner";

function Archive({ eventId, environment, theme }) {
    const { archiveLogs, error, isLoaded, refetchArchiveLogs } = useGetArchiveListApi(
        eventId,
        environment
    );

    if (!isLoaded) {
        return <LoadingSpinner />;
    }

    return (
        <div className="mx-2 pb-2">
            <div>
                {isLoaded
                    ? archiveLogs.map((item, index) => (
                          <ArchiveInstance
                              key={index}
                              item={item}
                              eventId={eventId}
                              environment={environment}
                              index={index}
                          />
                      ))
                    : ""}
            </div>
        </div>
    );
}

export default Archive;
