import './App.css';
import Home from './screens/home';
import Config from "./config"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CLIENT_NAME = Config.CLIENT_NAME;
function App() {
  document.title = 'Health Check';
  document.body.classList.add(localStorage.getItem('theme') + '-theme');
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route>
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <div>
        <ToastContainer />
      </div>
    </div >

  );
}

export default App;
