import { useState, useEffect } from "react";
import ApiConfig from "../services/ApiConfig";
import axios from "axios";

const GetEventDetails = (eventID, environment) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = await ApiConfig();
            config.method = "get";
            config.url +=
                "/get_event_details?" +
                "environment=" +
                environment +
                "&event_id=" +
                eventID;
            const response = await axios(config);
            resolve(response.data);
        } catch (error) {
            reject(error.response.data);
        }
    });
};

const useGetEventDetailsApi = (eventID, environment) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);

    const refetchEventDetails = async () => {
        setIsLoaded(false);
        try {
            const response = await GetEventDetails(eventID, environment);
            setIsLoaded(true)
            setEventDetails(response)
        } catch (error) {
            setError(error);
        }
    };
    useEffect(() => {
        const GetEvent = async () => {
            try {
                const response = await GetEventDetails(eventID, environment);
                setIsLoaded(true);
                
                if (Object.keys(response).length) {
                    setEventDetails(response);
                }
                else {
                    setError("Event not found!");
                }
            } catch (error) {
                setError(error);
            }
        };

        GetEvent();

    }, []);

    return { eventDetails, error, isLoaded, refetchEventDetails };
};

export default useGetEventDetailsApi;
